/* Banner / Slider
================================================== */
#banner {
  position: relative;
  overflow: hidden;
  height: auto;

  * {
    font-family: $primary-font !important;
  }

  .moduletable {
    height: 100%;

    .banner-text {
      position: absolute;
      right: 0;
      bottom: 2em;
      left: 0;
      z-index: 9;
      .row {
        padding: 0;
      }

      h1 {
        font-size: 4em;
        color: $white;
        line-height: 1;
        margin-bottom: 0;
        padding: 0;
        text-align: left;

        @include bp(xxlarge) {
          font-size: 4.2em;
        }

        @include bp_max(large) {
          font-size: 3.2em;
        }

        @include bp_max(smedium) {
          font-size: 2.2em;
        }
      }

      @include bp_max(mlarge) {
        z-index: 3;
        bottom: 0.5em;
        left: 0.5em;
      }
    }
  }
}
.banner {
  &__wrap {
    & > div {
      @include bp_max(xlarge) {
        padding: 200px 16px 120px 16px !important;
      }
      @include bp_max(mlarge) {
        padding-top: 180px !important;
      }
    }
  }
  &__row {
    .n2-ss-layer-row-inner {
      @include bp_max(xlarge) {
        flex-direction: column;
      }
    }
  }
  &__column {
    @include bp_max(xlarge) {
      width: 100% !important;
    }
  }
  &__title {
    h1 {
      max-width: 768px;
      @include bp_max(mlarge) {
        font-size: 2rem !important;
      }
      @include bp_max(medium) {
        font-size: 1.6rem !important;
      }
    }
    &--2 {
      position: absolute;
      z-index: 90;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      height: 100%;
      inset: 0;
      top: 10%;
      h1 {
        max-width: 100%;
        margin: 0;
        color: $white;
        text-align: center;
        font-size: 2.4rem !important;
        @include bp_max(mlarge) {
          font-size: 2rem !important;
        }
        @include bp_max(medium) {
          font-size: 1.6rem !important;
        }
      }
    }
  }
  &__circle-graphic {
    display: block;
    @include bp(xlarge) {
      display: none;
    }
  }
  &__buttons {
    display: flex;
    @include bp_max(large) {
      flex-direction: column;
      text-align: left;
    }
    p {
      margin: 0;
    }
    .button {
      margin: 0 2rem 1rem 0;
    }
  }
  &__video {
    z-index: 0 !important;
    .n2_ss_video_player {
      //border-radius: 50%;
      overflow: hidden;
      z-index: 10;
      -webkit-mask-image: url("#{$image-directory}hexagon.svg");
      mask-image: url("#{$image-directory}hexagon.svg");

      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      -webkit-mask-position: center;
      mask-position: center;

      -webkit-mask-size: 100%;
      mask-size: 100%;
    }
    video {
      margin: 0 0.5rem;
    }
  }
  &__graphic {
    z-index: 0 !important;
    @include bp_max(xlarge) {
      width: auto !important;
      transform: none !important;
      img {
        width: 400px !important;
      }
    }
    &--1 {
      @include bp_max(xlarge) {
        top: auto !important;
        bottom: -7rem !important;
        right: -10rem !important;
        left: auto !important;
      }
    }
    &--2 {
      @include bp_max(xlarge) {
        top: auto !important;
        bottom: -10rem !important;
        left: -20rem !important;
      }
    }
  }
}
.internal-banner {
  height: 440px;
}
