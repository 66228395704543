/* Main
================================================== */
main {
  padding: 6rem 0;
  background-color: $light-blue;
  p,
  li {
    font-family: $primary-font;
    font-weight: 300;
  }
  strong {
    font-family: $primary-font;
    font-weight: 600;
  }
  ul,
  ol {
    list-style-position: inside;
    padding: 0;
  }
  .item-page {
    @include bp(large) {
      padding: 0 3rem;
    }
  }
  .article {
    &--center {
      text-align: center;
    }
  }
  .wf-columns {
    gap: 0 4rem;
  }
  ul {
    @extend .decorated;
  }
}

.main {
  &--black,
  &--blue {
    background-color: $black;
    * {
      color: $white;
    }
    .ba-form-field-item .ba-field-label-wrapper > * {
      color: $white;
    }
    .black {
      color: $black;
    }
    .dark-blue {
      color: $dark-blue;
    }
    .primary-colour {
      color: $primary-colour;
    }
    .secondary-colour {
      color: $secondary-colour;
    }
  }
  &--blue {
    background-color: $medium-blue;
  }
}

.label-warning {
  display: none;
}

.decorated {
  list-style: none;
  margin-left: 1rem;
  li {
    position: relative;
    list-style-type: none;
    padding-left: 2rem;
    &:not(:first-of-type) {
      margin-top: 0.5rem;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0.35rem;
      left: 0;
      width: 100%;
      max-width: 16px;
      height: 16px;
      border: 3px solid darken($light-blue, 8%);
      border-radius: 50%;
    }
  }
}
