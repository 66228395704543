/* Page Specific Styling
================================================== */
/*#home {
  .section--testimonials {
    .row {
      padding-top: 2rem;
    }
  }
}*/

/* Testimonials List
================================================== */
.testimonials {
  .latestnews-item {
    margin-bottom: 2rem !important;
    padding: 2rem !important;
    background-color: $white;
    font-size: 1.2rem !important;
    &:before {
      display: none;
    }
  }
  .newshead {
    float: none !important;
    margin-right: 2rem !important;
  }
  .innernews,
  .innerpicture {
    display: flex;
    align-items: center;
  }
  .innernews {
    @include bp_max(medium) {
      flex-direction: column;
    }
  }
  .innerpicture {
    justify-content: center;
    width: 160px;
    height: 160px;
    margin: 0 1rem;
    @include bp_max(medium) {
      margin-bottom: 2rem;
    }
    img {
      object-fit: cover;
    }
  }
  .detail {
    display: block;
    line-height: 1;
    margin: 0 0 0.5rem 0;
    padding: 0;
  }
  .delimiter {
    display: none;
  }
  .newsintro,
  &__company {
    font-weight: normal !important;
  }
  .newsintro {
    margin-bottom: 1rem;
  }
  &__name {
    p {
      font-family: $primary-font !important;
      font-weight: 200;
      font-weight: normal !important;
    }
  }
}
