/* Header
================================================== */
header {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: $global-transition;
  box-shadow: 0px 0px 9px #031835;

  @include bp_max(mlarge) {
    padding: 0.5rem 1rem;
  }

  .menu.active & {
    padding: 0 1rem;
  }

  .column {
    @include bp(xxlarge) {
      padding: 0;
      /*.shrink & {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
      }*/
    }
    /*@include bp(xxxlarge) {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }*/
  }

  &.shrink,
  .menu-active & {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-between;
    z-index: 999;
    transition: $global-transition;

    @include bp_max(large) {
      background: $white;
    }

    .header__logo {
      position: relative;
      transition: $global-transition;

      &:before {
        background: $secondary-colour;
      }

      &:after {
        border-color: $secondary-colour transparent transparent transparent;
      }

      img {
        width: 160px;
        @include bp(medium) {
          width: 180px;
        }
        @include bp(xxlarge) {
          width: 160px;
        }
      }
    }

    .nav--main {
      &.active {
        @include bp_max(large) {
          top: 4em;
        }
      }
    }
  }
  &.shrink {
    padding: 0.5rem;
    .nav--main {
      .button {
        font-size: 1rem;
      }
    }
  }
  .menu-active & {
    .nav--main {
      .button {
        font-size: 1.2rem !important;
      }
    }
  }
}

.header {
  &__wrap {
    @extend %flex;
    align-items: center;
  }
  &__top {
    @include bp_max(xxxlarge) {
      position: relative;
      z-index: 90;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      background-color: $white;
    }
  }
  &__logo {
    position: relative;
    z-index: 90;
    @extend %flex;
    align-items: center;
    transition: $global-transition;
    padding-right: 2em;
    p {
      margin: 0;
      text-align: center;
      font-size: 1.2rem;
      font-family: $secondary-font;
      font-weight: 200;
      @include bp(medium) {
        font-size: 1.5rem;
      }
      @include bp(xxlarge) {
        font-size: 1.3rem;
      }
      /*@include bp(xxxxlarge) {
        font-size: 1.5rem;
      }*/
      &:first-of-type {
        margin-right: 0.5rem;
      }
      strong {
        display: block;
        font-size: 0.8rem;
        font-family: $secondary-font;
        font-weight: 600;
        @include bp(medium) {
          font-size: 1.05rem;
        }
        @include bp(xxlarge) {
          font-size: 0.9rem;
        }
        /*@include bp(xxxxlarge) {
          font-size: 1.05rem;
        }*/
      }
    }
    a {
      display: block;
      transition: $global-transition;
    }
    img {
      display: block;
      width: 160px;
      transition: $global-transition;
      @include bp(medium) {
        width: 180px;
      }
      @include bp(xxlarge) {
        width: 160px;
      }
      /*@include bp(xxxxlarge) {
        width: 220px;
      }*/
    }
    &-location {
      width: 160px;
      text-align: center;
      @include bp(medium) {
        width: 220px;
      }
      @include bp(xxlarge) {
        width: 180px;
      }
      /*@include bp(xxxxlarge) {
        width: 220px;
      }*/
    }
  }
}
