// 1. File Paths
// --------------
// e.g. url('#{$image-directory}...')
$public-path: '../';
$image-directory: '../images/';
$font-directory: '../fonts/';

// 2. Width
// --------------
$global-width: 1480px;

// 3. Fonts
// --------------
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap');
$primary-font: 'Plus Jakarta Sans', sans-serif;
$secondary-font: 'Plus Jakarta Sans', sans-serif;
$tertiary-font: 'Plus Jakarta Sans', sans-serif;

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #d7007f;
$secondary-colour: #01a1d5;

.primary-colour {
  color: $primary-colour;
}
.secondary-colour {
  color: $secondary-colour;
}

/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #e6e6e6 !default;
$medium-grey: #cacaca !default;
$dark-grey: #8a8a8a !default;
//$black: #031835 !default;
$black: #002240 !default;
$off-black: #3c3c3c !default;
$white: #ffffff !default;
$off-white: #edf0ef !default;
$silver: #c1c0bb;

$body-background: $white !default;
$body-font-color: $black !default;

//$dark-blue: #031835;
$dark-blue: #002240;
$medium-blue: #002240;
$light-blue: #dbe2ed;

.black {
  color: $black;
}
.dark-blue {
  color: $dark-blue;
}

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 110% !default;
$base-line-height: 1.6 !default;
$base-font-color: $off-black !default;
$base-font-weight: normal !default;
$base-background-color: $white !default;

// 6. Headings
// --------------
$base-heading-font-family: $secondary-font !default;
$base-heading-line-height: 1.2 !default;
$base-heading-font-weight: 600 !default;
$base-h1-font-size: 2rem !default;
$base-h2-font-size: 1.8rem !default;
$base-h3-font-size: 1.6rem !default;
$base-h4-font-size: 1.5rem !default;
$base-h5-font-size: 1.4rem !default;
$base-h6-font-size: 1.2rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 576px,
  mlarge: 768px,
  large: 992px,
  xlarge: 1280px,
  xxlarge: $global-width,
  xxxlarge: 1700px,
  xxxxlarge: 1920px,
);
$breakpoint-classes: (small smedium medium mlarge large xlarge xxlarge xxlarge);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.8 !default;

// 9. Global
// --------------
$global-lineheight: 1.8;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: 400ms ease-in-out;
