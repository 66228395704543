/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
  .row {
    padding: 6rem 0;
  }
}
.section {
  &--no-padding-top {
    .row {
      padding-top: 0;
    }
  }
  &--no-padding-bottom {
    .row {
      padding-bottom: 0;
    }
  }

  &--narrow {
    .row {
      padding: 3rem;
    }
  }

  &--text-center {
    text-align: center;
  }

  &--colour {
    background-color: $primary-colour;
    * {
      color: $white;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $secondary-colour;
    }
  }

  &--black {
    background-color: $black;
    * {
      color: $white;
    }
  }

  &--blue {
    background-color: $medium-blue;
    * {
      color: $white;
    }
  }

  &--grey {
    background-color: $off-white;
  }

  &--light {
    background-color: $light-blue;
  }

  &--graphic-bottom {
    background-color: $light-blue;
    background-image: url('#{$image-directory}graphic-bg-4.svg');
    background-repeat: no-repeat;
    background-position: 50% 400px;
    background-size: 2000px;
  }

  &--graphic-right {
    background-color: $light-blue;
    background-image: url('#{$image-directory}hexagon-pink.svg');
    background-repeat: no-repeat;
    background-position: 150% 50%;
    background-size: 800px;
    @include bp_max(large) {
      background-position: 50% 800px;
    }
    @include bp_max(medium) {
      background-position: 50% 500px;
    }
  }
}

/* Stats
================================================== */
.stats {
  .row {
    padding: 1rem 0;
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    @include opacity(0);
    transition: $global-transition;
    @include bp_max(mlarge) {
      flex-wrap: wrap;
      justify-content: normal;
    }
  }
  li {
    width: 100%;
    list-style: none;
    margin: 1rem 0;
    padding: 0 1.5rem;
    border-left: 4px solid $primary-colour;
    @include bp_max(mlarge) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include bp_max(smedium) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  p {
    margin: 0.5rem 0;
    font-size: 1.4rem;
    color: $black;
  }
  strong {
    display: block;
    font-size: 3rem;
    font-weight: 700;
    font-family: $secondary-font;
  }
}

/* Testimonials Slider
================================================== */
.section--testimonials {
  .n2-ss-slider .n2-ss-slider-controls-above,
  .n2-ss-slider .n2-ss-slider-controls-below {
    flex-flow: row;
    justify-content: center;
    .nextend-arrow {
      margin: 0 1rem;
      transition: $global-transition;
    }
  }
  .n2-ss-layer-row {
    @include bp_max(medium) {
      padding: 0 !important;
    }
  }
}
.testimonials-slider {
  &__quote,
  &__company {
    p {
      font-family: $primary-font !important;
      font-weight: 600;
    }
  }
  &__quote {
    p {
      @include bp_max(medium) {
        font-size: 1.1rem !important;
      }
    }
  }
  &__name {
    p {
      font-family: $primary-font !important;
      font-weight: 200;
    }
  }
  .image__mask {
    width: 160px !important;
    margin: 0 !important;
    right: 0 !important;
    left: 0 !important;
    -webkit-mask-image: url('#{$image-directory}hexagon.svg');
    mask-image: url('#{$image-directory}hexagon.svg');

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    -webkit-mask-position: center;
    mask-position: center;

    -webkit-mask-size: 100%;
    mask-size: 100%;
  }
}

/* CTAs
================================================== */
.ctas {
  padding-top: 2rem;
  &--three-columns {
    gap: 0;
    .cta {
      flex: 1 1 0px;
      min-width: 0;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      flex: 0 0 100%;
      max-width: 100%;
      @include bp(mlarge) {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }
    }
  }
}
.cta {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
  text-align: center;
  * {
    transition: $global-transition;
  }
  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 2rem auto;
    padding: 0;
    img {
      max-width: 180px;
      max-height: 100%;
    }
  }
  h3 {
    margin: 0;
    font-family: $primary-font;
    font-size: 600;
    font-weight: normal;
    font-size: 1rem;
    color: $white;
    text-transform: initial;
  }
  h5 {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  p {
    margin-top: 0;
  }
}
a.cta:hover {
  h3 {
    color: $secondary-colour;
  }
  img {
    @include scale(0.95);
  }
}
.cta {
  &--border {
    .cta {
      &__image {
        padding: 2.5rem;
        &::after {
          content: '';
          position: absolute;
          width: 180px;
          height: 180px;
          background: url('#{$image-directory}hexagon-white.svg') no-repeat 50%
            50%;
        }
      }
    }
    a.cta:hover {
      img {
        @include scale(0.95);
      }
    }
    &-bg {
      .cta {
        &__image {
          padding: 10px;
        }
      }
      img {
        overflow: hidden;
        display: block;
        width: 180px !important;
        height: 180px !important;
        max-height: 100% !important;
        -webkit-mask-image: url('#{$image-directory}hexagon.svg');
        mask-image: url('#{$image-directory}hexagon.svg');

        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;

        -webkit-mask-position: center;
        mask-position: center;

        -webkit-mask-size: 100%;
        mask-size: 100%;
      }
    }
  }
}
a.cta--border-bg:hover {
  &:hover {
    img {
      @include scale(1);
      opacity: 0.9;
    }
  }
}

/* Gallery
================================================== */
.section--gallery {
  h2 {
    margin-bottom: 2rem;
  }
  .button {
    margin: 2rem 0 0 0;
  }
}

/* Countdown
================================================== */
.section--countdown {
  h2 {
    margin-bottom: 2rem;
  }
  .button {
    margin: 1rem 0 0 0;
  }
}
.countdown {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.6rem;
  @include bp_max(smedium) {
    flex-direction: column;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__number {
    margin-bottom: 0.25rem;
    font-family: $secondary-font;
    color: $primary-colour;
  }
  &__text {
    color: $white;
    font-size: 0.6em;
  }
}
