/* News & Partners
================================================== */
.latest-news {
  ul.latestnews-items {
    flex-flow: column !important;
    justify-content: normal !important;
    align-items: normal !important;
    flex-wrap: wrap !important;
    @include bp(large) {
      flex-flow: row !important;
      flex-wrap: wrap !important;
    }
  }
  .latestnews-item {
    flex: 1 1 0px;
    min-width: 0;
    max-width: 100%;
    margin-bottom: 2rem !important;
    padding: 0 0.9375rem 2rem !important;
    @include bp(large) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-bottom: 0rem !important;
    }
    &::before {
      display: none;
    }
    & > a {
      display: block;
      height: 100%;
      &:hover {
        strong {
          margin-left: 0.5rem;
        }
      }
    }
    .newsSection & {
      border: 0;
    }
    &.active {
      opacity: 1 !important;
    }
  }
  .newshead {
    float: none !important;
    margin: 0 !important;
  }
  .picture {
    width: 100%;
    max-width: 100% !important;
    max-height: 240px !important;
    margin-bottom: 1.5rem;
    a {
      height: 240px !important;
    }
  }
  .innerpicture {
    width: 100%;
    height: 240px !important;
    &:after {
      content: '';
      display: block;
      padding-bottom: 60%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .news {
    position: relative !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 0 64px !important;
    background-color: $white !important;
    border: none !important;
  }
  .newsinfo {
    padding: 0 1rem 1rem 1rem;
    * {
      color: $black;
    }
  }
  .newstitle {
    font-size: 1.6rem;
    font-family: $secondary-font !important;
    font-weight: normal !important;
    line-height: 1.2 !important;
    margin: 0 0 1rem 0 !important;
    a {
      color: $black;
    }
  }
  .newsintro {
    line-height: 1.6 !important;
    font-size: 1.1rem;
    font-size: $base-font-size;
    color: $black;
  }
  .detail_category,
  .detail_tags {
    display: inline-flex;
    padding: 0.8rem;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 1.1rem;
    color: $white;
  }
  .detail_category {
    background: $dark-blue;
  }
  .detail_tags {
    background: $primary-colour;
  }
  .detail_date {
    display: block;
    margin: 1em 0;
    font-family: $primary-font;
    font-weight: 400;
    text-transform: uppercase;
    color: $primary-colour;
  }
  .delimiter {
    display: none;
  }
  .link {
    position: absolute !important;
    z-index: 1 !important;
    bottom: 0 !important;
    width: calc(100% - 2rem) !important;
    a {
      @extend .button;
      @extend .button--text;
      margin: 0;
    }
    strong {
      font-family: $tertiary-font !important;
    }
  }
  &--full {
    .newslist {
      ul.latestnews-items {
        flex-flow: row wrap !important;
      }
      .latestnews-item {
        @include bp(medium) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(large) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }
  &--contain {
    .innerpicture {
      display: flex;
      img {
        max-width: 75% !important;
        margin: auto;
        padding: 3rem 0;
        object-fit: contain;
      }
    }
  }
  &--sponsors {
    .news {
      padding: 0 !important;
    }
    .picture {
      margin: 0;
    }
  }
}

/* News
================================================== */
.latest-news {
  .row {
    padding: 6em 0;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h2 {
      margin: 0;
    }
  }
}

.news-item-article {
  padding: 1rem 0 3rem;
  background: $white;
  figure {
    text-align: center;
    img {
      max-height: 400px;
    }
  }
  h1 {
    color: $black;
    font-weight: 700;
  }
  .article-info.muted {
    display: block;
    color: $black;
    margin-bottom: 2em;
  }
  .share-buttons {
    margin-top: 4rem;
    text-align: center;
    .button {
      margin-top: 1rem;
    }
  }
}

.article-info.muted {
  display: none;
}
