.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 0;
  td,
  th {
    padding: 1rem 1.5rem;
    border-bottom: 0;
    border-right: 0;
    * {
      margin: 0;
    }
  }
  th {
    background-color: $secondary-colour;
    font-size: 1.1rem;
    * {
      color: $white;
    }
  }
  tr {
    background-color: lighten($light-blue, 2%);
    &:nth-child(2n) {
      background-color: $off-white;
    }
  }
}
