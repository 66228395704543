/* AOS
================================================== */
.editor.wf-editor-container {
  [data-aos^="fade"],
  [data-aos="fade-up"] {
    opacity: 1 !important;
    transition: none !important;
    transform: none !important;
  }
}
.admin {
  [data-aos] {
    opacity: 1 !important;
    transition: none !important;
    transform: none !important;
  }
}

/* Social
================================================== */
.social {
  ul {
    margin: 0;
  }
  li {
    list-style: none;
  }
  a {
    display: block;
    width: 3em;
    height: 3em;
    margin: 0.4em;
    background: $primary_colour;
    border-radius: 60%;
    &:hover {
      background: darken($primary-colour, 8%);
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3em;
      height: 3em;
    }
  }
  svg {
    width: 1.5em;
    height: 1.5em;
    transition: $global-transition;
    * {
      fill: $white;
      transition: $global-transition;
    }
  }
}

.social-fixed {
  @extend %va;
  position: fixed;
  right: 0;
  z-index: 90;
  padding: 16px 8px;
  @include border-radius(40px);
  @include bp_max(xlarge) {
    display: none;
  }
  @include bp_max(xxlarge) {
    position: absolute;
    top: 32rem;
  }
}

/* Card
================================================== */
.card {
  display: flex;
  flex-direction: column;
  &__image {
    width: 100%;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    position: relative;
    height: 100%;
    padding: 3rem;
    background: $white;
    text-align: left;
    .button {
      margin: 0;
    }
    &--button {
      padding-bottom: 5rem;
      @include bp(medium) {
        padding-bottom: 7rem;
      }
      @include bp(large) {
        padding-bottom: 5rem;
      }
      .button {
        position: absolute;
        bottom: 3rem;
      }
    }
  }
}

/* Card List
================================================== */
.card-list {
  li {
    &::before {
      display: none;
    }
  }
  &--full {
    .newslist {
      opacity: 1 !important;
      height: auto !important;
    }
  }
  &--three-columns {
    ul.latestnews-items {
      @include bp(medium) {
        flex-flow: row !important;
        flex-wrap: wrap !important;
      }
    }
    .latestnews-item {
      flex: 1 1 0px;
      min-width: 0;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      flex: 1;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 1rem 2rem !important;
      @include bp(medium) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      @include bp(large) {
        flex: 0 0 33.33% !important;
        max-width: 33.33% !important;
      }
    }
  }
  &--judges {
    .news {
      padding: 0 !important;
    }
    .picture {
      max-height: 240px !important;
      height: 240px;
    }
    .innerpicture {
      max-height: 240px !important;
      height: 240px;
    }
    .newsinfo {
      padding: 0 2rem 2rem;
    }
    .newsintro {
      font-size: 1rem !important;
    }
    .newsextra {
      margin-bottom: 1rem;
    }
    .detail {
      display: block;
      font-weight: 400;
      font-size: 0.9rem;
    }
    .newstitle {
      margin: 0 0 0.5rem 0 !important;
    }
    .newsintro {
      margin-top: 1rem;
    }
  }
}
