/* Navigation
================================================== */
.nav {
  width: 100%;
  margin: 0;
  padding: 0;
  transition: $global-transition;

  @include bp_max(xxxlarge) {
    opacity: 0;
  }

  @include bp(xxxlarge) {
    display: flex;
    align-items: center;
  }

  li {
    position: relative;
    left: 0;
    margin: 0;
    text-align: center;
    list-style: none;
  }

  a {
    display: block;
    padding: 20px;
    color: $off-black;
    font-size: 0.8rem;
    font-family: $secondary-font;
    font-weight: 300;
    text-decoration: none;
    line-height: normal;
    @include bp(xxxxlarge) {
      font-size: 0.9rem;
    }
  }

  a:hover,
  .active > a {
    color: $primary-colour;
  }

  .button,
  button {
    padding: 12px 26px !important;
    font-size: 0.8rem !important;
    @include bp(xxxxlarge) {
      margin-bottom: 0;
      padding: 14px 32px !important;
      font-size: 0.9rem !important;
    }
  }

  i {
    margin-left: 5px;
  }

  ul {
    display: none;
    width: 100%;
    position: static;
    margin: 0;
    padding: 0;
    font-size: 0.8em;

    @include bp(xxxlarge) {
      position: absolute;
      min-width: 150px;
      width: auto;
      top: 100%;
      left: 0;
      font-size: 1em;
      background-color: $white;
    }

    .menu-active & {
      @include bp_max(xxxlarge) {
        opacity: 1;
      }
    }

    li {
      width: 100%;
    }
  }

  li:hover > ul {
    @include bp(xxxlarge) {
      display: block;
    }
  }

  & > * {
    overflow: visible;
  }

  @include bp_max(xxxlarge) {
    &.active,
    & > ul.active {
      display: block;
    }

    &.active a {
      display: block;
      padding: 0.6em;
    }

    &.active li,
    &.active ul ul {
      width: 100%;
      height: auto;
      display: block;
      font-size: 1em !important;
    }

    &.active ul ul {
      display: block;
      position: inherit;
      top: auto;
    }
  }

  .parent {
    &:hover,
    &.active {
      & > a {
        color: $primary-colour;
        &:after {
          border-color: $primary-colour transparent transparent transparent;
        }
      }
    }

    > a {
      position: relative;
      padding-right: 26px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0.8em;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0;
        border-color: $off-black transparent transparent transparent;
        transition: $global-transition;
      }
    }
  }
}

.nav-child {
  li {
    a {
      color: $white;

      @include bp_max(xxxlarge) {
        padding: 10px 20px;
        color: $primary-colour;
      }

      &:hover {
        color: $secondary-colour;

        @include bp_max(xxxlarge) {
          color: $primary-colour;
        }
      }
    }

    &.active,
    &.current {
      a {
        color: $secondary-colour;

        &:hover {
          color: white;
        }
      }
    }
  }

  &.active {
    display: block !important;
  }
}

/* Main Navigation
================================================== */

.nav--main {
  position: relative;
  z-index: 80;
  width: auto;
  display: block;
  overflow: visible;
  margin-left: auto;

  @include bp_max(xxxlarge) {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    width: 100%;
    max-width: none;
    height: 3rem;
    transition: $global-transition;
  }

  a {
    display: block;
    text-transform: uppercase;
    @include bp(xxxlarge) {
      padding: 16px 14px;
    }
  }

  .nav {
    transition: $global-transition;

    @include bp_max(xxxlarge) {
      display: flex;
      flex-flow: column nowrap;
    }

    .button {
      margin: 2rem 0 0 0;
      color: $white;
      font-size: 1.2rem;
      font-family: $tertiary-font;
      @include bp(large) {
        margin: 0 0 0 1rem;
      }
      @include bp_max(xxxlarge) {
        font-size: 1rem;
      }
    }
  }

  .menu-active & {
    overflow: scroll;
    min-height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    display: block !important;

    .column {
      width: 100%;
      min-height: 100%;
      margin: 8rem 0;

      @include bp_max(xxxlarge) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .nav {
      opacity: 1;

      @include bp_max(xxxlarge) {
        height: 100%;
      }
      a {
        font-size: 1rem !important;
      }
      .mod-menu__sub {
        a {
          font-size: 0.8rem !important;
        }
      }
    }

    .mobileButtons {
      @include bp_max(mlarge) {
        display: block;
        clear: both;
        text-align: center;
      }
    }

    .button {
      margin: 2rem 0 0 0 !important;
    }
  }
}

body.menu-active {
  overflow: hidden;
}

/* Sitemap
================================================== */
.sitemap {
  .nav {
    align-items: initial;
    flex-direction: column;
    a {
      display: inline-flex;
      &:after {
        display: none;
      }
    }
    li,
    ul {
      width: 100%;
    }
    ul {
      display: block;
      position: static;
      background: transparent;
    }
    .button {
      &:hover {
        color: $white;
      }
    }
  }
}

/* Hamburger
================================================== */

.menu-link {
  position: relative;
  z-index: 90;
  display: block;
  cursor: pointer;
  height: 33px;
  margin: 10px 1rem 10px;
  background: transparent !important;
  border: none !important;

  span,
  span:before,
  span:after {
    width: 35px;
    height: 5px;
    position: absolute;
    top: 15px;
    display: block;
    content: '';
    background: $primary-colour;
    @include border-radius(1px);
    @include transition($global-transition);
  }

  span {
    &:before {
      top: -10px;
    }

    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  .menu-active & {
    span {
      background: transparent;

      &:before,
      &:after {
        top: 0;
        background: $primary-colour;
      }

      &:before {
        rotate: 45deg;
      }

      &:after {
        rotate: -45deg;
      }
    }
  }

  @include bp(xxxlarge) {
    display: none;
  }
}
