/* Sponsors Section
================================================== */
.sponsors-section {
  h4 {
    display: inline-block;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid $primary-colour;
    font-size: 1rem;
  }
}

/* Footer
================================================== */
footer {
  background: $black;
  width: 100%;
  padding: 6em 0 0;
  * {
    color: $white;
  }
  a:hover {
    color: $secondary-colour;
  }
  .button {
    margin: 2rem 0 0 0;
    font-size: 0.9rem;
  }
}

.footer {
  &__wrap {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    text-align: center;
    justify-content: space-between;

    & > div {
      @include bp_max(medium) {
        text-align: center;
      }
      @include bp_max(xlarge) {
        margin-bottom: 3rem;
      }
    }

    h3 {
      margin: 0 0 1rem;
      font-family: $primary-font;
      font-weight: 400;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 1.2rem;
      color: $primary-colour;
    }
    h4 {
      margin-bottom: 0.5rem;
      font-family: $primary-font;
      font-size: 1.2rem;
      font-weight: normal;
      strong {
        font-family: $secondary-font;
        font-weight: 600;
      }
    }
  }

  &__left {
    order: 4;
    @include bp(large) {
      align-self: flex-start;
      text-align: left;
      order: 3;
    }
    @include bp(large) {
      order: 1;
    }

    h3 {
      margin-bottom: 1rem;
    }
  }

  &__logo {
    img {
      max-width: 220px;
      height: auto;
      margin: 0 auto 2rem;
      @include bp(xlarge) {
        margin: 0 0 2rem 0;
      }
    }
  }

  &__sponsors {
    display: flex;
    flex-direction: column;
    img {
      margin: 0.5rem 0;
    }
  }

  &__social {
    @include bp(large) {
      margin-top: 1rem;
      margin-left: -1rem;
    }
    ul {
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
      @include bp_max(xlarge) {
        justify-content: center;
      }
    }
    a {
      background: transparent !important;
      &:hover {
        * {
          fill: $primary-colour;
        }
      }
    }
  }

  &__dates,
  &__hours,
  &__hocation {
    text-align: center;
    p {
      margin-bottom: 1.5rem;
    }
  }

  &__dates {
    order: 1;
    @include bp(large) {
      order: 2;
    }
  }

  &__hours {
    order: 2;
    @include bp(large) {
      order: 3;
    }
  }

  &__location {
    order: 3;
    @include bp(medium) {
      order: 4;
    }
  }

  &__bottom {
    width: 100%;
    margin-top: 3rem;
    padding: 1.5rem 2rem;
    background-color: darken($black, 6%);

    * {
      font-family: $primary-font;
      font-weight: 200;
      text-transform: uppercase;
    }

    .row {
      justify-content: space-between;
      align-items: center;
    }

    .footer__links,
    .footer__signature {
      font-size: 0.8em;
      @include bp_max(mlarge) {
        padding: 0.5rem 0;
        text-align: center;
      }
    }

    .footer__links {
      @include bp(medium) {
        justify-content: flex-start;
      }
      a {
        margin-right: 1rem;
      }
    }

    .footer__signature {
      display: flex;
      align-items: center;
      justify-content: center;
      @include bp(mlarge) {
        justify-content: flex-end;
      }
      a {
        display: flex;
        align-items: center;
      }
      img {
        margin-left: 1rem;
        width: 14em;
        transition: $global-transition;
      }
    }
  }
}
