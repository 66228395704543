@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap");
.primary-colour {
  color: #d7007f;
}

.secondary-colour {
  color: #01a1d5;
}

.black {
  color: #002240;
}

.dark-blue {
  color: #002240;
}

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
.header__logo, .header__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.social-fixed {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

/*@font-face {
  font-family: "plus_jakarta_sansitalic";
  src: url("../fonts/plusjakartasans-italic-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("../fonts/plusjakartasans-italic-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "plus_jakarta_sansregular";
  src: url("../fonts/plusjakartasans-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("../fonts/plusjakartasans-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "neue_haas_grotesk_display45Lt";
  src: url("../fonts/neuehaasdisplaylight-webfont.woff2")
      format("woff2"),
    url("../fonts/neuehaasdisplaylight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$primary-font--200: "neue_haas_grotesk_display45Lt";

@font-face {
  font-family: "neue_haas_grotesk_disp55roman";
  src: url("../fonts/neuehaasdisplayroman-webfont.woff2")
      format("woff2"),
    url("../fonts/neuehaasdisplayroman-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$primary-font--300: "neue_haas_grotesk_disp55roman";

@font-face {
  font-family: "neue_haas_grotesk_display65Md";
  src: url("../fonts/neuehaasdisplaymedium-webfont.woff2")
      format("woff2"),
    url("../fonts/neuehaasdisplaymedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$primary-font--400: "neue_haas_grotesk_display65Md";

@font-face {
  font-family: "neue_haas_grotesk_display75Bd";
  src: url("../fonts/neuehaasdisplaybold-webfont.woff2")
      format("woff2"),
    url("../fonts/neuehaasdisplaybold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$primary-font--600: "neue_haas_grotesk_display75Bd";

@font-face {
  font-family: "pf_beausans_prolight";
  src: url("../fonts/pfbeausanspro-light-webfont.woff2")
      format("woff2"),
    url("../fonts/pfbeausanspro-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$secondary-font--200: "pf_beausans_prolight";

@font-face {
  font-family: "pf_beausans_proregular";
  src: url("../fonts/pfbeausanspro-regular-webfont.woff2")
      format("woff2"),
    url("../fonts/pfbeausanspro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$secondary-font--300: "pf_beausans_proregular";

@font-face {
  font-family: "pf_beausans_prosemibold";
  src: url("../fonts/pfbeausanspro-semibold-webfont.woff2")
      format("woff2"),
    url("../fonts/pfbeausanspro-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$secondary-font--500: "pf_beausans_prosemibold";

@font-face {
  font-family: "pf_beausans_probold";
  src: url("../fonts/pfbeausanspro-bold-webfont.woff2") format("woff2"),
    url("../fonts/pfbeausanspro-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
$secondary-font--600: "pf_beausans_probold";

@import url("https://use.typekit.net/vwq3jcz.css");

$tertiary-font: brandon-grotesque, sans-serif;
*/
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.ba-form-page fieldset {
  padding: 0;
  border: none;
}
.ba-form-page .hide-label .ba-field-label-wrapper {
  display: none;
}
.ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.ba-form-page .ba-form-page-navigation-title,
.ba-form-page .upload-file-btn,
.ba-form-page .upload-file-input,
.ba-form-page .ba-field-container select,
.ba-form-page .ba-field-container textarea[readonly],
.ba-form-page .ba-field-container textarea,
.ba-form-page .ba-field-container input[type=text],
.ba-form-page .ba-field-container input[type=number],
.ba-form-page .ba-field-container input[type=password],
.ba-form-page .ba-field-container input[type=email],
.ba-form-page .ba-field-container select option,
.ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #3c3c3c !important;
}
.ba-form-page .ba-input-label-wrapper {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  text-transform: uppercase !important;
}
.ba-form-page .ba-icon-cloud-upload {
  color: #8a8a8a !important;
}
.ba-form-page .upload-file-btn {
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: 0.9rem !important;
}
.ba-form-page .upload-file-drag-drop-title {
  color: #3c3c3c !important;
}
.ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 0;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 0;
  border-right: 0;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #01a1d5;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}
table tr {
  background-color: #e2e7f0;
}
table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 110%;
  line-height: 1.6;
  color: #3c3c3c;
  font-weight: normal;
  background: #ffffff;
}

main,
details {
  display: block;
}

a {
  color: #01a1d5;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
a:hover {
  color: #3c3c3c;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5rem 0 1.5rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 1.2;
  font-weight: 600;
  color: #002240;
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.8rem;
}

h3,
.h3 {
  font-size: 1.6rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

.title--margin {
  margin-bottom: 1.5rem;
}

.button, .latest-news .link a,
button,
input[type=submit],
input[type=reset],
input[type=button],
.ba-form-submit-btn,
.upload-file-btn {
  display: inline-flex;
  margin-bottom: 24px;
  padding: 14px 32px;
  background: #d7007f;
  border: 4px solid #d7007f;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  cursor: pointer;
  transition: 400ms ease-in-out;
  -webkit-appearance: none;
  outline: 0;
  transition: 400ms ease-in-out;
}
.button:hover, .latest-news .link a:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
.ba-form-submit-btn:hover,
.upload-file-btn:hover {
  color: #ffffff;
  background: #ae0067;
  border-color: #ae0067;
}
.button:visited, .latest-news .link a:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited,
.ba-form-submit-btn:visited,
.upload-file-btn:visited {
  color: #ffffff;
}

.ba-form-submit-btn {
  background: #01a1d5 !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-transform: uppercase !important;
}
.ba-form-submit-btn:hover {
  color: #ffffff !important;
  background: #0182ac !important;
  border-color: #0182ac !important;
}

.button.full-width, .latest-news .link a.full-width,
button.full-width,
input[type=submit].full-width,
input[type=reset].full-width,
input[type=button].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button--outline {
  background: transparent;
  border: 4px solid #d7007f;
  color: #d7007f;
}
.button--outline:hover {
  background: #d7007f;
  border-color: #d7007f;
  color: #ffffff !important;
}
.button--outline:visited {
  color: #d7007f;
}

.button--secondary {
  background: #01a1d5;
  border-color: #01a1d5;
}
.button--secondary:hover {
  background: #0182ac;
  border-color: #0182ac;
  color: #ffffff;
}
.button--secondary:hover:visited {
  color: #ffffff;
}
.button--secondary.button--outline {
  background: transparent;
  border: 4px solid #01a1d5;
  color: #01a1d5;
}
.button--secondary.button--outline:hover {
  background: #01a1d5;
  border-color: #01a1d5;
  color: #ffffff !important;
}
.button--secondary.button--outline:visited {
  color: #01a1d5;
}

.button--2 {
  background: #c1c0bb;
  border-color: #c1c0bb;
}
.button--2:hover {
  background: #aeaca6;
  border-color: #aeaca6;
  color: #ffffff;
}
.button--2:hover:visited {
  color: #ffffff;
}
.button--2.button--outline {
  background: transparent;
  border: 4px solid #c1c0bb;
  color: #c1c0bb;
}
.button--2.button--outline:hover {
  background: #c1c0bb;
  border-color: #c1c0bb;
  color: #ffffff !important;
}
.button--2.button--outline:visited {
  color: #c1c0bb;
}

.button--text, .latest-news .link a {
  display: flex;
  align-items: center;
  padding-left: 0;
  background: transparent !important;
  border: none !important;
  color: #d7007f !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.2em;
}
.button--text:hover strong, .latest-news .link a:hover strong {
  margin-left: 0.5rem;
}
.button--text strong, .latest-news .link a strong {
  transition: 400ms ease-in-out;
}
.button--text svg, .latest-news .link a svg {
  display: inline-flex;
  width: 28px;
  height: 28px;
  margin-right: 0.5rem;
}

@media (min-width: 576px) {
  .button--align-left {
    justify-content: flex-start;
  }
}
@media (min-width: 576px) {
  .button--align-right {
    justify-content: flex-end;
  }
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.8.1
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1480px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 62em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 92.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 92.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 62em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 62em) and (min-width: 62em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 62em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 92.5em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 92.5em) and (min-width: 92.5em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 92.5em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 92.5em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 92.5em) and (min-width: 92.5em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 92.5em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

/* Main
================================================== */
main {
  padding: 6rem 0;
  background-color: #dbe2ed;
}
main p,
main li {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
}
main strong {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
}
main ul,
main ol {
  list-style-position: inside;
  padding: 0;
}
@media (min-width: 992px) {
  main .item-page {
    padding: 0 3rem;
  }
}
main .article--center {
  text-align: center;
}
main .wf-columns {
  gap: 0 4rem;
}
.main--black, .main--blue {
  background-color: #002240;
}
.main--black *, .main--blue * {
  color: #ffffff;
}
.main--black .ba-form-field-item .ba-field-label-wrapper > *, .main--blue .ba-form-field-item .ba-field-label-wrapper > * {
  color: #ffffff;
}
.main--black .black, .main--blue .black {
  color: #002240;
}
.main--black .dark-blue, .main--blue .dark-blue {
  color: #002240;
}
.main--black .primary-colour, .main--blue .primary-colour {
  color: #d7007f;
}
.main--black .secondary-colour, .main--blue .secondary-colour {
  color: #01a1d5;
}
.main--blue {
  background-color: #002240;
}

.label-warning {
  display: none;
}

.decorated, main ul {
  list-style: none;
  margin-left: 1rem;
}
.decorated li, main ul li {
  position: relative;
  list-style-type: none;
  padding-left: 2rem;
}
.decorated li:not(:first-of-type), main ul li:not(:first-of-type) {
  margin-top: 0.5rem;
}
.decorated li::before, main ul li::before {
  content: "";
  position: absolute;
  top: 0.35rem;
  left: 0;
  width: 100%;
  max-width: 16px;
  height: 16px;
  border: 3px solid #c0ccdf;
  border-radius: 50%;
}

/* Header
================================================== */
header {
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: 400ms ease-in-out;
  box-shadow: 0px 0px 9px #031835;
}
@media (max-width: 767px) {
  header {
    padding: 0.5rem 1rem;
  }
}
.menu.active header {
  padding: 0 1rem;
}
header .column, header .columns {
  /*@include bp(xxxlarge) {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }*/
}
@media (min-width: 1480px) {
  header .column, header .columns {
    padding: 0;
    /*.shrink & {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }*/
  }
}
header.shrink, .menu-active header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 999;
  transition: 400ms ease-in-out;
}
@media (max-width: 991px) {
  header.shrink, .menu-active header {
    background: #ffffff;
  }
}
header.shrink .header__logo, .menu-active header .header__logo {
  position: relative;
  transition: 400ms ease-in-out;
}
header.shrink .header__logo:before, .menu-active header .header__logo:before {
  background: #01a1d5;
}
header.shrink .header__logo:after, .menu-active header .header__logo:after {
  border-color: #01a1d5 transparent transparent transparent;
}
header.shrink .header__logo img, .menu-active header .header__logo img {
  width: 160px;
}
@media (min-width: 576px) {
  header.shrink .header__logo img, .menu-active header .header__logo img {
    width: 180px;
  }
}
@media (min-width: 1480px) {
  header.shrink .header__logo img, .menu-active header .header__logo img {
    width: 160px;
  }
}
@media (max-width: 991px) {
  header.shrink .nav--main.active, .menu-active header .nav--main.active {
    top: 4em;
  }
}
header.shrink {
  padding: 0.5rem;
}
header.shrink .nav--main .button, header.shrink .nav--main .latest-news .link a, .latest-news .link header.shrink .nav--main a {
  font-size: 1rem;
}
.menu-active header .nav--main .button, .menu-active header .nav--main .latest-news .link a, .latest-news .link .menu-active header .nav--main a {
  font-size: 1.2rem !important;
}

.header__wrap {
  align-items: center;
}
@media (max-width: 1699px) {
  .header__top {
    position: relative;
    z-index: 90;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    background-color: #ffffff;
  }
}
.header__logo {
  position: relative;
  z-index: 90;
  align-items: center;
  transition: 400ms ease-in-out;
  padding-right: 2em;
}
.header__logo p {
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 200;
  /*@include bp(xxxxlarge) {
    font-size: 1.5rem;
  }*/
}
@media (min-width: 576px) {
  .header__logo p {
    font-size: 1.5rem;
  }
}
@media (min-width: 1480px) {
  .header__logo p {
    font-size: 1.3rem;
  }
}
.header__logo p:first-of-type {
  margin-right: 0.5rem;
}
.header__logo p strong {
  display: block;
  font-size: 0.8rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  /*@include bp(xxxxlarge) {
    font-size: 1.05rem;
  }*/
}
@media (min-width: 576px) {
  .header__logo p strong {
    font-size: 1.05rem;
  }
}
@media (min-width: 1480px) {
  .header__logo p strong {
    font-size: 0.9rem;
  }
}
.header__logo a {
  display: block;
  transition: 400ms ease-in-out;
}
.header__logo img {
  display: block;
  width: 160px;
  transition: 400ms ease-in-out;
  /*@include bp(xxxxlarge) {
    width: 220px;
  }*/
}
@media (min-width: 576px) {
  .header__logo img {
    width: 180px;
  }
}
@media (min-width: 1480px) {
  .header__logo img {
    width: 160px;
  }
}
.header__logo-location {
  width: 160px;
  text-align: center;
  /*@include bp(xxxxlarge) {
    width: 220px;
  }*/
}
@media (min-width: 576px) {
  .header__logo-location {
    width: 220px;
  }
}
@media (min-width: 1480px) {
  .header__logo-location {
    width: 180px;
  }
}

/* Navigation
================================================== */
.nav {
  width: 100%;
  margin: 0;
  padding: 0;
  transition: 400ms ease-in-out;
}
@media (max-width: 1699px) {
  .nav {
    opacity: 0;
  }
}
@media (min-width: 1700px) {
  .nav {
    display: flex;
    align-items: center;
  }
}
.nav li {
  position: relative;
  left: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}
.nav a {
  display: block;
  padding: 20px;
  color: #3c3c3c;
  font-size: 0.8rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
  text-decoration: none;
  line-height: normal;
}
@media (min-width: 1920px) {
  .nav a {
    font-size: 0.9rem;
  }
}
.nav a:hover,
.nav .active > a {
  color: #d7007f;
}
.nav .button, .nav .latest-news .link a, .latest-news .link .nav a,
.nav button {
  padding: 12px 26px !important;
  font-size: 0.8rem !important;
}
@media (min-width: 1920px) {
  .nav .button, .nav .latest-news .link a, .latest-news .link .nav a,
  .nav button {
    margin-bottom: 0;
    padding: 14px 32px !important;
    font-size: 0.9rem !important;
  }
}
.nav i {
  margin-left: 5px;
}
.nav ul {
  display: none;
  width: 100%;
  position: static;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}
@media (min-width: 1700px) {
  .nav ul {
    position: absolute;
    min-width: 150px;
    width: auto;
    top: 100%;
    left: 0;
    font-size: 1em;
    background-color: #ffffff;
  }
}
@media (max-width: 1699px) {
  .menu-active .nav ul {
    opacity: 1;
  }
}
.nav ul li {
  width: 100%;
}
@media (min-width: 1700px) {
  .nav li:hover > ul {
    display: block;
  }
}
.nav > * {
  overflow: visible;
}
@media (max-width: 1699px) {
  .nav.active, .nav > ul.active {
    display: block;
  }
  .nav.active a {
    display: block;
    padding: 0.6em;
  }
  .nav.active li, .nav.active ul ul {
    width: 100%;
    height: auto;
    display: block;
    font-size: 1em !important;
  }
  .nav.active ul ul {
    display: block;
    position: inherit;
    top: auto;
  }
}
.nav .parent:hover > a, .nav .parent.active > a {
  color: #d7007f;
}
.nav .parent:hover > a:after, .nav .parent.active > a:after {
  border-color: #d7007f transparent transparent transparent;
}
.nav .parent > a {
  position: relative;
  padding-right: 26px;
}
.nav .parent > a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0.8em;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0;
  border-color: #3c3c3c transparent transparent transparent;
  transition: 400ms ease-in-out;
}

.nav-child li a {
  color: #ffffff;
}
@media (max-width: 1699px) {
  .nav-child li a {
    padding: 10px 20px;
    color: #d7007f;
  }
}
.nav-child li a:hover {
  color: #01a1d5;
}
@media (max-width: 1699px) {
  .nav-child li a:hover {
    color: #d7007f;
  }
}
.nav-child li.active a, .nav-child li.current a {
  color: #01a1d5;
}
.nav-child li.active a:hover, .nav-child li.current a:hover {
  color: white;
}
.nav-child.active {
  display: block !important;
}

/* Main Navigation
================================================== */
.nav--main {
  position: relative;
  z-index: 80;
  width: auto;
  display: block;
  overflow: visible;
  margin-left: auto;
}
@media (max-width: 1699px) {
  .nav--main {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    width: 100%;
    max-width: none;
    height: 3rem;
    transition: 400ms ease-in-out;
  }
}
.nav--main a {
  display: block;
  text-transform: uppercase;
}
@media (min-width: 1700px) {
  .nav--main a {
    padding: 16px 14px;
  }
}
.nav--main .nav {
  transition: 400ms ease-in-out;
}
@media (max-width: 1699px) {
  .nav--main .nav {
    display: flex;
    flex-flow: column nowrap;
  }
}
.nav--main .nav .button, .nav--main .nav .latest-news .link a, .latest-news .link .nav--main .nav a {
  margin: 2rem 0 0 0;
  color: #ffffff;
  font-size: 1.2rem;
  font-family: "Plus Jakarta Sans", sans-serif;
}
@media (min-width: 992px) {
  .nav--main .nav .button, .nav--main .nav .latest-news .link a, .latest-news .link .nav--main .nav a {
    margin: 0 0 0 1rem;
  }
}
@media (max-width: 1699px) {
  .nav--main .nav .button, .nav--main .nav .latest-news .link a, .latest-news .link .nav--main .nav a {
    font-size: 1rem;
  }
}
.menu-active .nav--main {
  overflow: scroll;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  display: block !important;
}
.menu-active .nav--main .column, .menu-active .nav--main .columns {
  width: 100%;
  min-height: 100%;
  margin: 8rem 0;
}
@media (max-width: 1699px) {
  .menu-active .nav--main .column, .menu-active .nav--main .columns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.menu-active .nav--main .nav {
  opacity: 1;
}
@media (max-width: 1699px) {
  .menu-active .nav--main .nav {
    height: 100%;
  }
}
.menu-active .nav--main .nav a {
  font-size: 1rem !important;
}
.menu-active .nav--main .nav .mod-menu__sub a {
  font-size: 0.8rem !important;
}
@media (max-width: 767px) {
  .menu-active .nav--main .mobileButtons {
    display: block;
    clear: both;
    text-align: center;
  }
}
.menu-active .nav--main .button, .menu-active .nav--main .latest-news .link a, .latest-news .link .menu-active .nav--main a {
  margin: 2rem 0 0 0 !important;
}

body.menu-active {
  overflow: hidden;
}

/* Sitemap
================================================== */
.sitemap .nav {
  align-items: initial;
  flex-direction: column;
}
.sitemap .nav a {
  display: inline-flex;
}
.sitemap .nav a:after {
  display: none;
}
.sitemap .nav li,
.sitemap .nav ul {
  width: 100%;
}
.sitemap .nav ul {
  display: block;
  position: static;
  background: transparent;
}
.sitemap .nav .button:hover, .sitemap .nav .latest-news .link a:hover, .latest-news .link .sitemap .nav a:hover {
  color: #ffffff;
}

/* Hamburger
================================================== */
.menu-link {
  position: relative;
  z-index: 90;
  display: block;
  cursor: pointer;
  height: 33px;
  margin: 10px 1rem 10px;
  background: transparent !important;
  border: none !important;
}
.menu-link span,
.menu-link span:before,
.menu-link span:after {
  width: 35px;
  height: 5px;
  position: absolute;
  top: 15px;
  display: block;
  content: "";
  background: #d7007f;
  border-radius: 1px;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
.menu-link span:before {
  top: -10px;
}
.menu-link span:after {
  top: auto;
  bottom: -10px;
}
.menu-active .menu-link span {
  background: transparent;
}
.menu-active .menu-link span:before, .menu-active .menu-link span:after {
  top: 0;
  background: #d7007f;
}
.menu-active .menu-link span:before {
  rotate: 45deg;
}
.menu-active .menu-link span:after {
  rotate: -45deg;
}
@media (min-width: 1700px) {
  .menu-link {
    display: none;
  }
}

/* Banner / Slider
================================================== */
#banner {
  position: relative;
  overflow: hidden;
  height: auto;
}
#banner * {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
#banner .moduletable {
  height: 100%;
}
#banner .moduletable .banner-text {
  position: absolute;
  right: 0;
  bottom: 2em;
  left: 0;
  z-index: 9;
}
#banner .moduletable .banner-text .row {
  padding: 0;
}
#banner .moduletable .banner-text h1 {
  font-size: 4em;
  color: #ffffff;
  line-height: 1;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}
@media (min-width: 1480px) {
  #banner .moduletable .banner-text h1 {
    font-size: 4.2em;
  }
}
@media (max-width: 991px) {
  #banner .moduletable .banner-text h1 {
    font-size: 3.2em;
  }
}
@media (max-width: 479px) {
  #banner .moduletable .banner-text h1 {
    font-size: 2.2em;
  }
}
@media (max-width: 767px) {
  #banner .moduletable .banner-text {
    z-index: 3;
    bottom: 0.5em;
    left: 0.5em;
  }
}

@media (max-width: 1279px) {
  .banner__wrap > div {
    padding: 200px 16px 120px 16px !important;
  }
}
@media (max-width: 767px) {
  .banner__wrap > div {
    padding-top: 180px !important;
  }
}
@media (max-width: 1279px) {
  .banner__row .n2-ss-layer-row-inner {
    flex-direction: column;
  }
}
@media (max-width: 1279px) {
  .banner__column {
    width: 100% !important;
  }
}
.banner__title h1 {
  max-width: 768px;
}
@media (max-width: 767px) {
  .banner__title h1 {
    font-size: 2rem !important;
  }
}
@media (max-width: 575px) {
  .banner__title h1 {
    font-size: 1.6rem !important;
  }
}
.banner__title--2 {
  position: absolute;
  z-index: 90;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  inset: 0;
  top: 10%;
}
.banner__title--2 h1 {
  max-width: 100%;
  margin: 0;
  color: #ffffff;
  text-align: center;
  font-size: 2.4rem !important;
}
@media (max-width: 767px) {
  .banner__title--2 h1 {
    font-size: 2rem !important;
  }
}
@media (max-width: 575px) {
  .banner__title--2 h1 {
    font-size: 1.6rem !important;
  }
}
.banner__circle-graphic {
  display: block;
}
@media (min-width: 1280px) {
  .banner__circle-graphic {
    display: none;
  }
}
.banner__buttons {
  display: flex;
}
@media (max-width: 991px) {
  .banner__buttons {
    flex-direction: column;
    text-align: left;
  }
}
.banner__buttons p {
  margin: 0;
}
.banner__buttons .button, .banner__buttons .latest-news .link a, .latest-news .link .banner__buttons a {
  margin: 0 2rem 1rem 0;
}
.banner__video {
  z-index: 0 !important;
}
.banner__video .n2_ss_video_player {
  overflow: hidden;
  z-index: 10;
  -webkit-mask-image: url("../images/hexagon.svg");
  mask-image: url("../images/hexagon.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}
.banner__video video {
  margin: 0 0.5rem;
}
.banner__graphic {
  z-index: 0 !important;
}
@media (max-width: 1279px) {
  .banner__graphic {
    width: auto !important;
    transform: none !important;
  }
  .banner__graphic img {
    width: 400px !important;
  }
}
@media (max-width: 1279px) {
  .banner__graphic--1 {
    top: auto !important;
    bottom: -7rem !important;
    right: -10rem !important;
    left: auto !important;
  }
}
@media (max-width: 1279px) {
  .banner__graphic--2 {
    top: auto !important;
    bottom: -10rem !important;
    left: -20rem !important;
  }
}

.internal-banner {
  height: 440px;
}

/* Page Specific Styling
================================================== */
/*#home {
  .section--testimonials {
    .row {
      padding-top: 2rem;
    }
  }
}*/
/* Testimonials List
================================================== */
.testimonials .latestnews-item {
  margin-bottom: 2rem !important;
  padding: 2rem !important;
  background-color: #ffffff;
  font-size: 1.2rem !important;
}
.testimonials .latestnews-item:before {
  display: none;
}
.testimonials .newshead {
  float: none !important;
  margin-right: 2rem !important;
}
.testimonials .innernews,
.testimonials .innerpicture {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .testimonials .innernews {
    flex-direction: column;
  }
}
.testimonials .innerpicture {
  justify-content: center;
  width: 160px;
  height: 160px;
  margin: 0 1rem;
}
@media (max-width: 575px) {
  .testimonials .innerpicture {
    margin-bottom: 2rem;
  }
}
.testimonials .innerpicture img {
  object-fit: cover;
}
.testimonials .detail {
  display: block;
  line-height: 1;
  margin: 0 0 0.5rem 0;
  padding: 0;
}
.testimonials .delimiter {
  display: none;
}
.testimonials .newsintro, .testimonials__company {
  font-weight: normal !important;
}
.testimonials .newsintro {
  margin-bottom: 1rem;
}
.testimonials__name p {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 200;
  font-weight: normal !important;
}

/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
}
section .row {
  padding: 6rem 0;
}

.section--no-padding-top .row {
  padding-top: 0;
}
.section--no-padding-bottom .row {
  padding-bottom: 0;
}
.section--narrow .row {
  padding: 3rem;
}
.section--text-center {
  text-align: center;
}
.section--colour {
  background-color: #d7007f;
}
.section--colour * {
  color: #ffffff;
}
.section--colour h1,
.section--colour h2,
.section--colour h3,
.section--colour h4,
.section--colour h5,
.section--colour h6 {
  color: #01a1d5;
}
.section--black {
  background-color: #002240;
}
.section--black * {
  color: #ffffff;
}
.section--blue {
  background-color: #002240;
}
.section--blue * {
  color: #ffffff;
}
.section--grey {
  background-color: #edf0ef;
}
.section--light {
  background-color: #dbe2ed;
}
.section--graphic-bottom {
  background-color: #dbe2ed;
  background-image: url("../images/graphic-bg-4.svg");
  background-repeat: no-repeat;
  background-position: 50% 400px;
  background-size: 2000px;
}
.section--graphic-right {
  background-color: #dbe2ed;
  background-image: url("../images/hexagon-pink.svg");
  background-repeat: no-repeat;
  background-position: 150% 50%;
  background-size: 800px;
}
@media (max-width: 991px) {
  .section--graphic-right {
    background-position: 50% 800px;
  }
}
@media (max-width: 575px) {
  .section--graphic-right {
    background-position: 50% 500px;
  }
}

/* Stats
================================================== */
.stats .row {
  padding: 1rem 0;
}
.stats ul {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  transition: 400ms ease-in-out;
}
@media (max-width: 767px) {
  .stats ul {
    flex-wrap: wrap;
    justify-content: normal;
  }
}
.stats li {
  width: 100%;
  list-style: none;
  margin: 1rem 0;
  padding: 0 1.5rem;
  border-left: 4px solid #d7007f;
}
@media (max-width: 767px) {
  .stats li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 479px) {
  .stats li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.stats p {
  margin: 0.5rem 0;
  font-size: 1.4rem;
  color: #002240;
}
.stats strong {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  font-family: "Plus Jakarta Sans", sans-serif;
}

/* Testimonials Slider
================================================== */
.section--testimonials .n2-ss-slider .n2-ss-slider-controls-above,
.section--testimonials .n2-ss-slider .n2-ss-slider-controls-below {
  flex-flow: row;
  justify-content: center;
}
.section--testimonials .n2-ss-slider .n2-ss-slider-controls-above .nextend-arrow,
.section--testimonials .n2-ss-slider .n2-ss-slider-controls-below .nextend-arrow {
  margin: 0 1rem;
  transition: 400ms ease-in-out;
}
@media (max-width: 575px) {
  .section--testimonials .n2-ss-layer-row {
    padding: 0 !important;
  }
}

.testimonials-slider__quote p, .testimonials-slider__company p {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 600;
}
@media (max-width: 575px) {
  .testimonials-slider__quote p {
    font-size: 1.1rem !important;
  }
}
.testimonials-slider__name p {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 200;
}
.testimonials-slider .image__mask {
  width: 160px !important;
  margin: 0 !important;
  right: 0 !important;
  left: 0 !important;
  -webkit-mask-image: url("../images/hexagon.svg");
  mask-image: url("../images/hexagon.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

/* CTAs
================================================== */
.ctas {
  padding-top: 2rem;
}
.ctas--three-columns {
  gap: 0;
}
.ctas--three-columns .cta {
  flex: 1 1 0px;
  min-width: 0;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .ctas--three-columns .cta {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem;
  text-align: center;
}
.cta * {
  transition: 400ms ease-in-out;
}
.cta__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  margin: 0 auto 2rem auto;
  padding: 0;
}
.cta__image img {
  max-width: 180px;
  max-height: 100%;
}
.cta h3 {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 600;
  font-weight: normal;
  font-size: 1rem;
  color: #ffffff;
  text-transform: initial;
}
.cta h5 {
  margin-bottom: 0.5rem;
  text-align: center;
}
.cta p {
  margin-top: 0;
}

a.cta:hover h3 {
  color: #01a1d5;
}
a.cta:hover img {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.cta--border .cta__image {
  padding: 2.5rem;
}
.cta--border .cta__image::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  background: url("../images/hexagon-white.svg") no-repeat 50% 50%;
}
.cta--border a.cta:hover img {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.cta--border-bg .cta__image {
  padding: 10px;
}
.cta--border-bg img {
  overflow: hidden;
  display: block;
  width: 180px !important;
  height: 180px !important;
  max-height: 100% !important;
  -webkit-mask-image: url("../images/hexagon.svg");
  mask-image: url("../images/hexagon.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

a.cta--border-bg:hover:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0.9;
}

/* Gallery
================================================== */
.section--gallery h2 {
  margin-bottom: 2rem;
}
.section--gallery .button, .section--gallery .latest-news .link a, .latest-news .link .section--gallery a {
  margin: 2rem 0 0 0;
}

/* Countdown
================================================== */
.section--countdown h2 {
  margin-bottom: 2rem;
}
.section--countdown .button, .section--countdown .latest-news .link a, .latest-news .link .section--countdown a {
  margin: 1rem 0 0 0;
}

.countdown {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.6rem;
}
@media (max-width: 479px) {
  .countdown {
    flex-direction: column;
  }
}
.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.countdown__number {
  margin-bottom: 0.25rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #d7007f;
}
.countdown__text {
  color: #ffffff;
  font-size: 0.6em;
}

/* News & Partners
================================================== */
.latest-news ul.latestnews-items {
  flex-flow: column !important;
  justify-content: normal !important;
  align-items: normal !important;
  flex-wrap: wrap !important;
}
@media (min-width: 992px) {
  .latest-news ul.latestnews-items {
    flex-flow: row !important;
    flex-wrap: wrap !important;
  }
}
.latest-news .latestnews-item {
  flex: 1 1 0px;
  min-width: 0;
  max-width: 100%;
  margin-bottom: 2rem !important;
  padding: 0 0.9375rem 2rem !important;
}
@media (min-width: 992px) {
  .latest-news .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-bottom: 0rem !important;
  }
}
.latest-news .latestnews-item::before {
  display: none;
}
.latest-news .latestnews-item > a {
  display: block;
  height: 100%;
}
.latest-news .latestnews-item > a:hover strong {
  margin-left: 0.5rem;
}
.newsSection .latest-news .latestnews-item {
  border: 0;
}
.latest-news .latestnews-item.active {
  opacity: 1 !important;
}
.latest-news .newshead {
  float: none !important;
  margin: 0 !important;
}
.latest-news .picture {
  width: 100%;
  max-width: 100% !important;
  max-height: 240px !important;
  margin-bottom: 1.5rem;
}
.latest-news .picture a {
  height: 240px !important;
}
.latest-news .innerpicture {
  width: 100%;
  height: 240px !important;
}
.latest-news .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.latest-news .innerpicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.latest-news .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 0 64px !important;
  background-color: #ffffff !important;
  border: none !important;
}
.latest-news .newsinfo {
  padding: 0 1rem 1rem 1rem;
}
.latest-news .newsinfo * {
  color: #002240;
}
.latest-news .newstitle {
  font-size: 1.6rem;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: normal !important;
  line-height: 1.2 !important;
  margin: 0 0 1rem 0 !important;
}
.latest-news .newstitle a {
  color: #002240;
}
.latest-news .newsintro {
  line-height: 1.6 !important;
  font-size: 1.1rem;
  font-size: 110%;
  color: #002240;
}
.latest-news .detail_category,
.latest-news .detail_tags {
  display: inline-flex;
  padding: 0.8rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  color: #ffffff;
}
.latest-news .detail_category {
  background: #002240;
}
.latest-news .detail_tags {
  background: #d7007f;
}
.latest-news .detail_date {
  display: block;
  margin: 1em 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #d7007f;
}
.latest-news .delimiter {
  display: none;
}
.latest-news .link {
  position: absolute !important;
  z-index: 1 !important;
  bottom: 0 !important;
  width: calc(100% - 2rem) !important;
}
.latest-news .link a {
  margin: 0;
}
.latest-news .link strong {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.latest-news--full .newslist ul.latestnews-items {
  flex-flow: row wrap !important;
}
@media (min-width: 576px) {
  .latest-news--full .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .latest-news--full .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.latest-news--contain .innerpicture {
  display: flex;
}
.latest-news--contain .innerpicture img {
  max-width: 75% !important;
  margin: auto;
  padding: 3rem 0;
  object-fit: contain;
}
.latest-news--sponsors .news {
  padding: 0 !important;
}
.latest-news--sponsors .picture {
  margin: 0;
}

/* News
================================================== */
.latest-news .row {
  padding: 6em 0;
}
.latest-news__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.latest-news__title h2 {
  margin: 0;
}

.news-item-article {
  padding: 1rem 0 3rem;
  background: #ffffff;
}
.news-item-article figure {
  text-align: center;
}
.news-item-article figure img {
  max-height: 400px;
}
.news-item-article h1 {
  color: #002240;
  font-weight: 700;
}
.news-item-article .article-info.muted {
  display: block;
  color: #002240;
  margin-bottom: 2em;
}
.news-item-article .share-buttons {
  margin-top: 4rem;
  text-align: center;
}
.news-item-article .share-buttons .button, .news-item-article .share-buttons .latest-news .link a, .latest-news .link .news-item-article .share-buttons a {
  margin-top: 1rem;
}

.article-info.muted {
  display: none;
}

/* Sponsors Section
================================================== */
.sponsors-section h4 {
  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 4px solid #d7007f;
  font-size: 1rem;
}

/* Footer
================================================== */
footer {
  background: #002240;
  width: 100%;
  padding: 6em 0 0;
}
footer * {
  color: #ffffff;
}
footer a:hover {
  color: #01a1d5;
}
footer .button, footer .latest-news .link a, .latest-news .link footer a {
  margin: 2rem 0 0 0;
  font-size: 0.9rem;
}

.footer__wrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  text-align: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .footer__wrap > div {
    text-align: center;
  }
}
@media (max-width: 1279px) {
  .footer__wrap > div {
    margin-bottom: 3rem;
  }
}
.footer__wrap h3 {
  margin: 0 0 1rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.2rem;
  color: #d7007f;
}
.footer__wrap h4 {
  margin-bottom: 0.5rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
}
.footer__wrap h4 strong {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
}
.footer__left {
  order: 4;
}
@media (min-width: 992px) {
  .footer__left {
    align-self: flex-start;
    text-align: left;
    order: 3;
  }
}
@media (min-width: 992px) {
  .footer__left {
    order: 1;
  }
}
.footer__left h3 {
  margin-bottom: 1rem;
}
.footer__logo img {
  max-width: 220px;
  height: auto;
  margin: 0 auto 2rem;
}
@media (min-width: 1280px) {
  .footer__logo img {
    margin: 0 0 2rem 0;
  }
}
.footer__sponsors {
  display: flex;
  flex-direction: column;
}
.footer__sponsors img {
  margin: 0.5rem 0;
}
@media (min-width: 992px) {
  .footer__social {
    margin-top: 1rem;
    margin-left: -1rem;
  }
}
.footer__social ul {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
}
@media (max-width: 1279px) {
  .footer__social ul {
    justify-content: center;
  }
}
.footer__social a {
  background: transparent !important;
}
.footer__social a:hover * {
  fill: #d7007f;
}
.footer__dates, .footer__hours, .footer__hocation {
  text-align: center;
}
.footer__dates p, .footer__hours p, .footer__hocation p {
  margin-bottom: 1.5rem;
}
.footer__dates {
  order: 1;
}
@media (min-width: 992px) {
  .footer__dates {
    order: 2;
  }
}
.footer__hours {
  order: 2;
}
@media (min-width: 992px) {
  .footer__hours {
    order: 3;
  }
}
.footer__location {
  order: 3;
}
@media (min-width: 576px) {
  .footer__location {
    order: 4;
  }
}
.footer__bottom {
  width: 100%;
  margin-top: 3rem;
  padding: 1.5rem 2rem;
  background-color: #001221;
}
.footer__bottom * {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 200;
  text-transform: uppercase;
}
.footer__bottom .row {
  justify-content: space-between;
  align-items: center;
}
.footer__bottom .footer__links,
.footer__bottom .footer__signature {
  font-size: 0.8em;
}
@media (max-width: 767px) {
  .footer__bottom .footer__links,
  .footer__bottom .footer__signature {
    padding: 0.5rem 0;
    text-align: center;
  }
}
@media (min-width: 576px) {
  .footer__bottom .footer__links {
    justify-content: flex-start;
  }
}
.footer__bottom .footer__links a {
  margin-right: 1rem;
}
.footer__bottom .footer__signature {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .footer__bottom .footer__signature {
    justify-content: flex-end;
  }
}
.footer__bottom .footer__signature a {
  display: flex;
  align-items: center;
}
.footer__bottom .footer__signature img {
  margin-left: 1rem;
  width: 14em;
  transition: 400ms ease-in-out;
}

/* AOS
================================================== */
.editor.wf-editor-container [data-aos^=fade],
.editor.wf-editor-container [data-aos=fade-up] {
  opacity: 1 !important;
  transition: none !important;
  transform: none !important;
}

.admin [data-aos] {
  opacity: 1 !important;
  transition: none !important;
  transform: none !important;
}

/* Social
================================================== */
.social ul {
  margin: 0;
}
.social li {
  list-style: none;
}
.social a {
  display: block;
  width: 3em;
  height: 3em;
  margin: 0.4em;
  background: #d7007f;
  border-radius: 60%;
}
.social a:hover {
  background: #ae0067;
}
.social a p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
}
.social svg {
  width: 1.5em;
  height: 1.5em;
  transition: 400ms ease-in-out;
}
.social svg * {
  fill: #ffffff;
  transition: 400ms ease-in-out;
}

.social-fixed {
  position: fixed;
  right: 0;
  z-index: 90;
  padding: 16px 8px;
  border-radius: 40px;
}
@media (max-width: 1279px) {
  .social-fixed {
    display: none;
  }
}
@media (max-width: 1479px) {
  .social-fixed {
    position: absolute;
    top: 32rem;
  }
}

/* Card
================================================== */
.card {
  display: flex;
  flex-direction: column;
}
.card__image {
  width: 100%;
  height: 300px;
}
.card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card__content {
  position: relative;
  height: 100%;
  padding: 3rem;
  background: #ffffff;
  text-align: left;
}
.card__content .button, .card__content .latest-news .link a, .latest-news .link .card__content a {
  margin: 0;
}
.card__content--button {
  padding-bottom: 5rem;
}
@media (min-width: 576px) {
  .card__content--button {
    padding-bottom: 7rem;
  }
}
@media (min-width: 992px) {
  .card__content--button {
    padding-bottom: 5rem;
  }
}
.card__content--button .button, .card__content--button .latest-news .link a, .latest-news .link .card__content--button a {
  position: absolute;
  bottom: 3rem;
}

/* Card List
================================================== */
.card-list li::before {
  display: none;
}
.card-list--full .newslist {
  opacity: 1 !important;
  height: auto !important;
}
@media (min-width: 576px) {
  .card-list--three-columns ul.latestnews-items {
    flex-flow: row !important;
    flex-wrap: wrap !important;
  }
}
.card-list--three-columns .latestnews-item {
  flex: 1 1 0px;
  min-width: 0;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  flex: 1;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1rem 2rem !important;
}
@media (min-width: 576px) {
  .card-list--three-columns .latestnews-item {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
@media (min-width: 992px) {
  .card-list--three-columns .latestnews-item {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }
}
.card-list--judges .news {
  padding: 0 !important;
}
.card-list--judges .picture {
  max-height: 240px !important;
  height: 240px;
}
.card-list--judges .innerpicture {
  max-height: 240px !important;
  height: 240px;
}
.card-list--judges .newsinfo {
  padding: 0 2rem 2rem;
}
.card-list--judges .newsintro {
  font-size: 1rem !important;
}
.card-list--judges .newsextra {
  margin-bottom: 1rem;
}
.card-list--judges .detail {
  display: block;
  font-weight: 400;
  font-size: 0.9rem;
}
.card-list--judges .newstitle {
  margin: 0 0 0.5rem 0 !important;
}
.card-list--judges .newsintro {
  margin-top: 1rem;
}