.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.ba-form-submit-btn,
.upload-file-btn {
  display: inline-flex;
  margin-bottom: 24px;
  padding: 14px 32px;
  background: $primary-colour;
  border: 4px solid $primary-colour;
  color: $white;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  font-family: $tertiary-font;
  cursor: pointer;
  transition: $global-transition;
  -webkit-appearance: none;
  outline: 0;
  transition: $global-transition;

  &:hover {
    color: $white;
    background: darken($primary-colour, 8%);
    border-color: darken($primary-colour, 8%);
  }
  &:visited {
    color: $white;
  }
}

.ba-form-submit-btn {
  background: $secondary-colour !important;
  font-family: $tertiary-font !important;
  color: $white !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-transform: uppercase !important;

  &:hover {
    color: $white !important;
    background: darken($secondary-colour, 8%) !important;
    border-color: darken($secondary-colour, 8%) !important;
  }
}
.button.full-width,
button.full-width,
input[type="submit"].full-width,
input[type="reset"].full-width,
input[type="button"].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button--outline {
  background: transparent;
  border: 4px solid $primary-colour;
  color: $primary-colour;
  &:hover {
    background: $primary-colour;
    border-color: $primary-colour;
    color: $white !important;
  }
  &:visited {
    color: $primary-colour;
  }
}

.button--secondary {
  background: $secondary-colour;
  border-color: $secondary-colour;
  &:hover {
    background: darken($secondary-colour, 8%);
    border-color: darken($secondary-colour, 8%);
    color: $white;
    &:visited {
      color: $white;
    }
  }
  &.button--outline {
    background: transparent;
    border: 4px solid $secondary-colour;
    color: $secondary-colour;
    &:hover {
      background: $secondary-colour;
      border-color: $secondary-colour;
      color: $white !important;
    }
    &:visited {
      color: $secondary-colour;
    }
  }
}

.button--2 {
  background: $silver;
  border-color: $silver;
  &:hover {
    background: darken($silver, 8%);
    border-color: darken($silver, 8%);
    color: $white;
    &:visited {
      color: $white;
    }
  }
  &.button--outline {
    background: transparent;
    border: 4px solid $silver;
    color: $silver;
    &:hover {
      background: $silver;
      border-color: $silver;
      color: $white !important;
    }
    &:visited {
      color: $silver;
    }
  }
}

.button--text {
  display: flex;
  align-items: center;
  padding-left: 0;
  background: transparent !important;
  border: none !important;
  color: $primary-colour !important;
  font-family: $tertiary-font;
  font-size: 1.2em;
  &:hover {
    strong {
      margin-left: 0.5rem;
    }
  }
  strong {
    transition: $global-transition;
  }
  svg {
    display: inline-flex;
    width: 28px;
    height: 28px;
    margin-right: 0.5rem;
  }
}

.button {
  &--align-left {
    @include bp(medium) {
      justify-content: flex-start;
    }
  }
  &--align-right {
    @include bp(medium) {
      justify-content: flex-end;
    }
  }
}
